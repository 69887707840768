// @/utils/request.ts
import axios, { AxiosRequestConfig } from 'axios'

// @/types/index.ts
export interface MyResponseType<T = any> {
    code: number;
    message: string;
    data: T;
}



const instance = axios.create({
    baseURL: 'https://ocean.ibalbal.com',
    // baseURL: "/ocean",
    // baseURL: "/",
    timeout: 10000,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
    },

})

const http = async <T = any>(config: AxiosRequestConfig): Promise<MyResponseType<T>> => {
    try {
        const { data } = await instance.request<MyResponseType<T>>(config)
        data.code === 0
            ? console.log(data.message) // 成功消息提示
            : console.error(data.message) // 失败消息提示
        return data
    } catch (err) {
        // @ts-ignore
        const message = err.message || '请求失败'
        console.error(message) // 失败消息提示
        return {
            code: -1,
            message,
            data: null as any
        }
    }
}

export default http
