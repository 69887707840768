import {Divider, Link, Text} from '@nextui-org/react';
import React from 'react';
import {AcmeLogo} from '../INavbar/AcmeLogo';
import {Box} from '../styles/box';
import {Flex} from '../styles/flex';
import {QRCodeMpIcon} from "../Icons/QRCodeMpIcon";

export const Footer = () => {
   return (
      <Flex
         css={{
            py: '$20',
            px: '$6',
         }}
      >
         <Box as={'footer'} css={{width: '100%'}}>
            <Flex
               justify={'center'}
               wrap={'wrap'}
               align={'start'}
               css={{
                  'gap': '$10',
                  '&  ul': {
                     margin: 0,
                  },
                  '@sm': {},
               }}
            >
               <Flex
                  css={{gap: '$5', w: '250px'}}
                  direction={'column'}
                  align={'start'}
               >
                  <AcmeLogo />
                  {/*方案*/}
                  <Box as={'ul'} css={{gap: '$5', listStyle: 'none'}}>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           网站建设
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           微信公众号开发
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           微信小程序开发
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           APP开发
                        </Text>
                     </Box>
                  </Box>
               </Flex>
               <Flex
                  css={{gap: '$5', w: '250px'}}
                  direction={'column'}
                  align={'start'}
               >
                  <Text h5>资讯</Text>
                  <Box as={'ul'} css={{gap: '$5', listStyle: 'none'}}>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           技术分享
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           运营推广
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           小程序开发
                        </Text>
                     </Box>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           行业新闻
                        </Text>
                     </Box>
                  </Box>
               </Flex>
               <Flex
                  css={{gap: '$5', w: '250px'}}
                  direction={'column'}
                  align={'start'}
               >
                  <Text h5>联系我们</Text>
                  <Box as={'ul'} css={{gap: '$5', listStyle: 'none'}}>
                     <Box as={'li'}>
                        <Text span css={{color: '$accents8'}}>
                           邮箱：ibalbal@163.com
                        </Text>
                     </Box>
                     {/*<Box as={'li'}>*/}
                     {/*   <Text span css={{color: '$accents8'}}>*/}
                     {/*      上海市金山区廊下镇景乐路228号*/}
                     {/*   </Text>*/}
                     {/*</Box>*/}
                     <Box as={'li'}>
                     </Box>
                  </Box>
               </Flex>
               <Flex
                  css={{gap: '$5', w: '250px'}}
                  direction={'column'}
                  align={'center'}
               >
                  <Text  h5>关于我们</Text>
                  <Box as={'ul'} css={{gap: '$5', listStyle: 'none'}}>
                     <Box as={'li'}>
                        <QRCodeMpIcon  width={100} height={100}/>
                     </Box>
                  </Box>
               </Flex>
               {/*<Flex*/}
               {/*   css={{gap: '$5', w: '250px'}}*/}
               {/*   direction={'column'}*/}
               {/*   align={'center'}*/}
               {/*>*/}
               {/*   <Text h5>Press</Text>*/}
               {/*   <Box as={'ul'} css={{gap: '$5', listStyle: 'none'}}>*/}
               {/*      <Box as={'li'}>*/}
               {/*         <Text span css={{color: '$accents8'}}>*/}
               {/*            First Link*/}
               {/*         </Text>*/}
               {/*      </Box>*/}
               {/*      <Box as={'li'}>*/}
               {/*         <Text span css={{color: '$accents8'}}>*/}
               {/*            Second Link*/}
               {/*         </Text>*/}
               {/*      </Box>*/}
               {/*      <Box as={'li'}>*/}
               {/*         <Text span css={{color: '$accents8'}}>*/}
               {/*            Third Link*/}
               {/*         </Text>*/}
               {/*      </Box>*/}
               {/*      <Box as={'li'}>*/}
               {/*         <Text span css={{color: '$accents8'}}>*/}
               {/*            Forth Link*/}
               {/*         </Text>*/}
               {/*      </Box>*/}
               {/*   </Box>*/}
               {/*</Flex>*/}
            </Flex>
            <Box
               css={{
                  'px': '$10',
                  '@md': {
                     px: '$56',
                  },
               }}
            >
               <Divider
                  css={{
                     mt: '$14',
                     display: 'flex',
                     justifyContent: 'center',
                  }}
               />
               <Flex
                  //   justify={'between'}
                  align={'center'}
                  wrap={'wrap'}
                  css={{
                     'pt': '$8',
                     'gap': '$10',
                     'justifyContent': 'center',
                     '@md': {
                        justifyContent: 'space-between',
                     },
                  }}
               >
                  <Flex
                     css={{
                        gap: '$10',
                     }}
                     wrap={'wrap'}
                  >
                     <AcmeLogo />
                     <AcmeLogo />
                     <AcmeLogo />
                     <AcmeLogo />
                  </Flex>
                  <Flex
                     css={{
                        gap: '$6',
                     }}
                  >
                     <Link underline color="text" href="https://beian.miit.gov.cn/">
                        {/*沪ICP备2023007360号-1*/}
                        豫ICP备18029750号-9
                     </Link>
                  </Flex>
                  <Flex
                     css={{
                        gap: '$6',
                     }}
                  >
                     <Text span css={{color: '$accents8'}}>
                        © Copyright 2023 YUJIGU Inc.
                     </Text>
                  </Flex>
               </Flex>
            </Box>
         </Box>
      </Flex>
   );
};
